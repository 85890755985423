import styled from "styled-components";
import Page from "../../mixins/Page";

export default styled.div`
  ${Page}

  .top-wrapper {
    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .points-info {
        text-align: center;
        color: white;
        font-size: 1.5rem;
        font-weight: 700;
        padding-bottom: 1.2rem;
      }
    }
  }

  h3 {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 2rem 0 0;
    font-weight: 700;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid #abb6ad;
  }

  .awards-list {
    overflow-y: auto;
  }

  .awards-loading {
    text-align: center;

    img {
      width: 3rem;
    }
  }

  .more-arrow {
    text-align: center;
    font-size: 3rem;
  }
`;
