const ORDERS_DETAILS_GET = "ORDERS_DETAILS_GET";

const getOrdersDetails = function (payload) {
  return {
    type: ORDERS_DETAILS_GET,
    payload,
  };
};

export { ORDERS_DETAILS_GET, getOrdersDetails };
