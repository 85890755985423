import api from "../api/farmersApi";
import { formatPoints } from "../utils";
import { getOrdersDetails } from "../store/ordersDetails/actions";
import { updateUserPoints, updateUserPointsUsed } from "../store/user/actions";
import { toggleLoading } from "../store/ui/actions";
import { cleanCart } from "../store/shoppingCart/actions";
import { logout } from "./user";
import moment from "moment";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export function processOrder() {
  return async (dispatch, getState) => {
    const {
      user: { id: userId },
      shoppingCart,
    } = getState();

    dispatch(toggleLoading(true));

    const [errorOrder, response] = await api.createOrder({ client_id: userId });

    let error = errorOrder;

    if (errorOrder) {
      if (error.status === 401) dispatch(logout());
    } else {
      const {
        data: { id: orderId },
      } = response;
      const detailPromise = shoppingCart.map((c) =>
        api.createOrderDetail({
          order_id: orderId,
          inventory_price_id: c.priceId,
          real_price: c.value,
          quantity: 1,
        })
      );

      const detailResults = await Promise.allSettled(detailPromise);
      const detailError = detailResults.find((r) => r.status === "rejected");

      if (detailError) {
        error = detailError.reason;
        await api.deleteOrder(orderId);
      } else {
        const [errorOrderStatus] = await api.updateOrder(orderId, {
          client_id: userId,
          state: "done",
        });

        if (errorOrderStatus) {
          error = errorOrderStatus;
          await api.deleteOrder(orderId);
        } else {
          /* eslint-disable no-unused-vars */
          const [_, points] = await api.countUserPoints(userId);

          if (points) {
            dispatch(updateUserPoints(points.total));
          }

          dispatch(cleanCart());

          dispatch(updateUserPointsUsed(undefined));
        }
      }
    }

    dispatch(toggleLoading(false));

    return error;
  };
}

export function fetchOrders() {
  return async (dispatch, getState) => {
    const {
      user: { id: userId, pointsUsed },
    } = getState();

    if (pointsUsed !== undefined) return;

    dispatch(toggleLoading(true));

    const [error, data] = await api.getOrdersByUserId(userId);

    if (error) {
      if (error.status === 401) dispatch(logout());
    } else {
      let newPointsUsed = 0;

      const ordersDetails = data
        .filter((order) => order.state === "done")
        .reduce((acc, order) => {
          const details = order.details.map((detail) => {
            const value = +detail.real_price;
            newPointsUsed += value;

            return {
              id: detail.id,
              orderId: order.id,
              value,
              valueFormat: `- ${formatPoints(value)}`,
              title: detail.inventory_price.inventory.name,
              image: `${SERVER_URL}img/inventories/${detail.inventory_price.inventory.image}`,
              registered: order.created_at,
              registeredFormat: moment(order.created_at, "YYYY-MM-DD").format(
                "DD MMM YY"
              ),
            };
          });

          return [...acc, ...details];
        }, []);

      dispatch(getOrdersDetails(ordersDetails));

      dispatch(updateUserPointsUsed(newPointsUsed));
    }

    dispatch(toggleLoading(false));

    return error;
  };
}
