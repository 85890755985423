import styled from "styled-components";
import Page from "../../mixins/Page";

export default styled.div`
  ${Page}

  .top-wrapper {
    .profile-pic {
      width: 6rem;
      height: 6rem;
      background-size: cover;
      background-position: center;
      border: 0.3rem solid white;
      border-radius: 100%;
      margin: 0 auto;
      transform: translateY(5.4rem);
      background-color: white;
    }
  }

  h3 {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 2rem 0 0;
    font-weight: 700;
    padding-bottom: 1rem;
  }

  .points-wrapper {
    padding: 2rem 0;
    margin: 2rem 0;
    border-top: 0.1rem solid #abb6ad;
    border-bottom: 0.1rem solid #abb6ad;
    p {
      text-align: center;
      color: #f5801f;
      font-size: 2.5rem;
      line-height: 3rem;

      &.points-count {
        font-weight: 700;
        font-size: 4.2rem;
        line-height: 5rem;
      }
    }
  }

  .btn {
    width: 100%;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    border-radius: 0.8rem;
    margin: 0.6rem 0;
    background: #ebebeb;
    text-decoration: none;
    color: black;
  }
`;
