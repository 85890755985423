import React from 'react'

import Checkbox from '../../components/shared/Checkbox'

import ProductItemStyle from './style';

export default function ProductItem({image, name, description, valueFormat, onChecked, checked = false }) {
    const [innerChecked, setInnerChecked] = React.useState(checked);

    const onChange = () => {
        setInnerChecked(onChecked(innerChecked));
    }

    return <ProductItemStyle className="product-item">
        <div className="product-image">
            <img src={image} alt="imagen de producto"/>
        </div>
        <div className="product-info">
            <p><strong>{name}</strong></p>
            <p>{description}</p>
            <p className="product-value"><strong>VALOR: {valueFormat} PTS.</strong></p>
        </div>
        <div className="product-check">
            <Checkbox checked={innerChecked} onChange={onChange} />
        </div>
    </ProductItemStyle>
}