import React from 'react';
import { useSelector } from 'react-redux'

export default function useComponentWithUser(ComponentWithUser, ComponentWithoutUser) {
    const userId = useSelector(state => state.user.id);
    const render = React.useCallback((props) => {
        return userId ? <ComponentWithUser {...props} /> :  <ComponentWithoutUser {...props}/>
    }, [userId]);

    return render;
}