import styled from "styled-components";
import Page from "../../mixins/Page";

export default styled.div`
  ${Page}

  .top-wrapper {
    .profile-pic {
      width: 8rem;
      height: 8rem;
      background-size: cover;
      background-position: center;
      border: 0.3rem solid white;
      border-radius: 100%;
      margin: 0 2.5rem 0 auto;
      transform: translateY(4.1rem);
      position: relative;
      background-color: white;

      .edit-btn {
        position: absolute;
        bottom: -0.8rem;
        right: 1rem;
        background: white;
        border-radius: 50%;
        font-size: 1.4rem;
        box-shadow: 0 0 0.5rem;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
      }
    }
  }

  h3 {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 2rem 0 0;
    font-weight: 700;
    padding-bottom: 1.54rem;
    border-bottom: 0.1rem solid #abb6ad;
  }

  .user-name {
    font-size: 1.4rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid #abb6ad;
  }

  .join-horizontal {
    p {
      display: inline-block;
    }
  }

  .user-data {
    margin: 1.5rem 0;
  }

  p {
    font-size: 1.1rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
`;
