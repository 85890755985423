import styled from "styled-components";
import Page from "../../mixins/Page";

export default styled.div`
  ${Page}

  > section > section {
    height: calc(100vh - 7.7rem);
    width: 50%;

    > div {
      display: none;
    }
  }

  h2 {
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 700;
    text-align: center;
  }

  h3 {
    text-align: center;
    font-size: 2.6rem;
    line-height: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
    span {
      font-size: 1.6rem;
    }
  }

  .qr-result {
    padding: 1.8rem;
    background: #f1f1f1;
    border-radius: 1.2rem;
    margin: 0 -1rem;
  }

  .product-info {
    border-bottom: 0.1rem solid #abb6ad;
    border-top: 0.1rem solid #abb6ad;
    padding: 1rem 0;
    margin: 2rem 0;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  strong {
    font-weight: 700;
  }

  .cancel-btn {
    margin-top: 1rem;
  }
`;
