import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_CLEAN
} from "./actions";

function getStateFromLocalStorage() {
    try {
        const cartStr = localStorage.getItem("cart");

        if (cartStr) {
            const cart = JSON.parse(cartStr);

            if (Array.isArray(cart)) return cart;
        }
    } catch {}

    localStorage.removeItem("cart");

    return [];
}

const initialState = getStateFromLocalStorage();

export default function shoppingCartReducer(state = initialState, action) {
    switch (action.type) {
        case CART_ADD_ITEM: {
            const newCart = [...state, action.payload];
            localStorage.setItem("cart", JSON.stringify(newCart));
            return newCart;
        }
        case CART_REMOVE_ITEM: {
            const newCart = state.filter(p => p.id !== action.payload);
            localStorage.setItem("cart", JSON.stringify(newCart));
            return newCart;
        }
        case CART_CLEAN:
            localStorage.removeItem("cart");
            return [];
        default:
            return state;
    }
}