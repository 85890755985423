import styled from "styled-components";
import Page from "../../mixins/Page";

export default styled.div`
  ${Page}

  .top-wrapper {
    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .points-info {
        text-align: center;
        color: white;
        font-size: 1.5rem;
        font-weight: 700;
        padding-bottom: 1.2rem;
      }
    }
  }

  h3 {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 2rem 0 0;
    font-weight: 700;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid #abb6ad;
  }

  .awards-list {
    overflow-y: auto;
  }

  p {
    line-height: 1.5rem;
  }

  .more-arrow {
    text-align: center;
    font-size: 3rem;
  }

  .cart-points {
    padding: 1rem 0;
    margin: 1rem 0;
    border-bottom: 0.1rem solid #abb6ad;
    border-top: 0.1rem solid #abb6ad;
  }

  .cart-message {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    margin-top: 1rem;
  }
`;
