import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form';

import TextInput from '../../components/shared/TextInput';
import GreenBlueButton from '../../components/shared/GreenBlueButton';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const messages = {
    required: "Campo requerido"
};

const schemaShape = {
    email: yup.string().email("Correo invalido").required(messages.required),
};

const schemaForm = yup.object().shape(schemaShape);

const FormStyled = styled.form`
    .txt-input {
        margin: 0.5rem 0;
    }

    button {
        margin-top: 2rem;
    }
`

const DEFAULT_VALUES = {
    email: "",
};

export default function ProfileForm ({ onSubmit, defaultValues = {} }) {
    const { handleSubmit, formState: { errors },  control } = useForm({
        mode: 'all',
        resolver: yupResolver(schemaForm),
        defaultValues: { ...DEFAULT_VALUES, ...defaultValues }
    });

    const innerOnSubmit = data => onSubmit && onSubmit(data);

    return <FormStyled onSubmit={handleSubmit(innerOnSubmit)}>
                <TextInput 
                    name="email"
                    control={control}
                    errorMessage={errors.email?.message}
                    className="txt-input"
                    type="email"
                    label="Correo electronico"
                />
                <GreenBlueButton>Enviar</GreenBlueButton>
            </FormStyled>
}