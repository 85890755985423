import React from 'react';
import styled from 'styled-components';
import { useController } from 'react-hook-form';
import FontAwesome from 'react-fontawesome';

const Container = styled.div`
    label {
        font-size: 1.2rem;
    }

    > div {
        position: relative;
        > input {
            font-size: 1.2rem;
            border: 0;
            width: 100%;
            border-bottom: 0.1rem solid #abb6ad;
            padding: ${ props => props.hasIcon ? ' 0.7rem 0 0.7rem 2rem' : ' 0.7rem 0' };
            position: relative;
            background-color: transparent;
            &:focus {
                outline: 0 none;
            }
        }
        .fa {
            position: absolute;
            line-height: 2.8rem;
            font-size: 1.5rem;
        }
    }


    .error-message {
        color: red;
        font-size: 1rem;
        display: inline-block;
    }

`;

export default React.forwardRef(({ className, errorMessage, iconName, label, control, name, ...inputProps }, ref) => {
    const { field: input } = useController({ name, control });

    return <Container className={className} hasIcon={!!iconName} ref={ref}>
            { label 
                ? (<label for={name}>{label}</label>)
                : null 
            }
            <div>
                { iconName ? <FontAwesome name={iconName} /> : null }
                <input id={name} { ...input } {...inputProps}/>
            </div>
            {
                errorMessage 
                    ? <span className="error-message">{errorMessage}</span>
                    : null
            }
        </Container>;
});
