import styled from 'styled-components';

export default styled.button`
border: none;
border-radius: 0.7rem;
font-size: 2rem;
color: white;
padding: 0.7rem;
width: 100%;
background: rgb(46,159,58);
background: linear-gradient(45deg, ${props => props.color1} 0%, ${props => props.color2} 50%, ${props => props.color1} 100%);
`;
