import {
  USER_LOAD,
  USER_RESET,
  USER_UPDATE_POINTS,
  USER_UPDATE_POINTS_USED,
} from "./actions";
import { formatPoints } from "../../utils";

const initialState = {
  id: null,
  first_name: "",
  last_name: "",
  full_name: "",
  username: "",
  email: "",
  phone: "",
  gender: "",
  company_name: "",
  partner_number: "",
  birthday: "",
  birthday_format: "",
  image: "",
  points: 0,
  pointsFormat: "0",
  is_special: "0",
  created_at: null,
  created_at_format: null,
  pointsUsed: undefined,
  pointsUsedFormat: "0",
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOAD:
      return { ...state, ...action.payload };
    case USER_RESET:
      return { ...initialState };
    case USER_UPDATE_POINTS:
      return {
        ...state,
        points: action.payload,
        pointsFormat: formatPoints(action.payload),
      };
    case USER_UPDATE_POINTS_USED:
      return {
        ...state,
        pointsUsed: action.payload,
        pointsUsedFormat: formatPoints(action.payload),
      };
    default:
      return state;
  }
}
