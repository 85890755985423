const MY_POINTS_CLEAN_LIST = "MY_POINTS_CLEAN_LIST";
const MY_POINTS_APPEND = "MY_POINTS_APPEND";

const appendMyPointsList = (list, nextPage) => {
  return {
    type: MY_POINTS_APPEND,
    payload: { list, nextPage },
  };
};

const cleanMyPointsList = () => {
  return {
    type: MY_POINTS_CLEAN_LIST,
  };
};

export {
  MY_POINTS_CLEAN_LIST,
  MY_POINTS_APPEND,
  appendMyPointsList,
  cleanMyPointsList,
};
