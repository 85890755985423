import React from "react";

import SplashStyle from "./style";

function whichTransitionEvent() {
  const el = document.createElement("fakeelement");

  const animations = {
    animation: "animationend",
    OAnimation: "oAnimationEnd",
    MozAnimation: "animationend",
    WebkitAnimation: "webkitAnimationEnd",
  };

  for (let t in animations) {
    if (el.style[t] !== undefined) {
      return animations[t];
    }
  }
}

export default function Splash({ forceShow }) {
  const [display, setDisplay] = React.useState(true);

  React.useEffect(() => {
    const container = document.querySelector(".splash");

    container &&
      container.addEventListener(whichTransitionEvent(), () => {
        setDisplay(false);
      });
  }, []);

  if (!display && !forceShow) return null;

  return (
    <SplashStyle className="splash" forceShow={forceShow}>
      <div className="splash__logo">
        <img
          src={`${process.env.PUBLIC_URL}/images/logo-white.svg`}
          alt="logo app"
        />
      </div>
      <div className="splash__footer">
        <img src={`${process.env.PUBLIC_URL}/images/upl.png`} alt="logo upl" />
      </div>
    </SplashStyle>
  );
}
