import styled from "styled-components";

import Page from "../../mixins/Page";

const Bullet = styled.div`
  display: inline-block;
  border-radius: 50%;
  min-width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  font-weight: 700;
  margin-right: 1rem;
  background: ${(prop) => prop.color1};
  background: linear-gradient(
    45deg,
    ${(prop) => prop.color1} 0%,
    ${(prop) => prop.color2} 100%
  );
`;

export { Bullet };

export default styled.div`
  ${Page}

  h3 {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 2rem 0 0;
    font-weight: 700;
    padding-bottom: 1rem;
  }

  ul {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      padding: 1rem 0;

      img {
        margin-right: 1.4rem;
      }

      p {
        flex-grow: 1;
        line-height: 1.6rem;
      }

      & + li {
        border-top: 1px solid #eceded;
      }
    }
  }
`;
