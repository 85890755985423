import moment from "moment";

import api from "../api/farmersApi";
import { cleanInventory } from "../store/inventories/actions";
import { cleanCart } from "../store/shoppingCart/actions";
import { toggleLoading } from "../store/ui/actions";
import { resetUser, loadUser, updateUserPoints } from "../store/user/actions";
import { cleanMyPointsList } from "../store/myPoints/actions";

export function registerUser(payload) {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    const [error] = await api.register(payload);

    let errorElem = null;
    let successElem = null;

    if (error) {
      if (typeof error.message == "object") {
        errorElem = (
          <ul>
            {Object.values(error.message).map((messages) => {
              return messages.map((message) => {
                return <li>{message}</li>;
              });
            })}
          </ul>
        );
      } else {
        errorElem = <p>{error.message}</p>;
      }
    } else {
      successElem = <p>Usuario agregado exitosamente</p>;
    }

    dispatch(toggleLoading(false));

    return [errorElem, successElem];
  };
}

export function login({ rememberMe, ...payload }) {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    const [error, data] = await api.login(payload);

    let errorElem = null;
    let successElem = null;

    if (error) {
      errorElem = <p>{error.message}</p>;
    } else {
      localStorage.setItem("TKU", data.token);
    }

    dispatch(toggleLoading(false));

    return [errorElem, successElem];
  };
}

const extractUserDataFromResponse = (data) => {
  const {
    id,
    first_name,
    last_name,
    username,
    email,
    phone,
    gender,
    company_name,
    partner_number,
    birthday,
    image,
    is_special,
    created_at,
  } = data;

  return {
    id,
    first_name,
    last_name,
    full_name: `${first_name} ${last_name}`,
    username,
    email,
    phone,
    gender,
    company_name,
    partner_number,
    birthday,
    birthday_format: moment(birthday, "YYYY-MM-DD").format(
      "DD \\d\\e MMMM, YYYY"
    ),
    image,
    is_special,
    created_at,
    created_at_format: moment(created_at, "YYYY-MM-DD").format(
      "MMMM \\d\\e YYYY"
    ),
  };
};

export function getUserData() {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    let returnError = null;
    const [error, data] = await api.getUserData();

    returnError = error;

    if (error) {
      if (error.status === 401) dispatch(logout());
    } else {
      const user = extractUserDataFromResponse(data.user);

      const [pointsError, points] = await api.countUserPoints(user.id);

      if (pointsError) {
        returnError = pointsError;
      } else {
        dispatch(loadUser(user));
        dispatch(updateUserPoints(points.total));
      }
    }

    dispatch(toggleLoading(false));

    return returnError;
  };
}

export function updateUser(payload) {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    const [error, response] = await api.updateUser(payload.id, payload);

    let errorElem = null;
    let successElem = null;

    if (error) {
      if (error.status === 401) dispatch(logout());
      else {
        if (typeof error.message == "object") {
          errorElem = (
            <ul>
              {Object.values(error.message).map((messages) => {
                return messages.map((message) => {
                  return <li>{message}</li>;
                });
              })}
            </ul>
          );
        } else {
          errorElem = <p>{error.message}</p>;
        }
      }
    } else {
      const user = extractUserDataFromResponse(response.data);
      dispatch(loadUser(user));
      successElem = <p>Usuario actualizado exitosamente</p>;
    }

    dispatch(toggleLoading(false));

    return [errorElem, successElem];
  };
}

export function addPoint(key) {
  return async (dispatch, getState) => {
    const { user } = getState();

    dispatch(toggleLoading(true));

    const [error] = await api.addPointToUser({
      user_id: user.id,
      point_key: key,
    });

    if (error) {
      if (error.status === 401) dispatch(logout());
    } else {
      dispatch(cleanMyPointsList());
      /* eslint-disable no-unused-vars */
      const [_, points] = await api.countUserPoints(user.id);

      if (points) {
        dispatch(updateUserPoints(points.total));
      }
    }

    dispatch(toggleLoading(false));

    return error;
  };
}

export function logout() {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    await api.logout();

    dispatch(resetUser());
    dispatch(cleanCart());
    dispatch(cleanInventory());

    dispatch(toggleLoading(false));
  };
}
export function requestRestorePassword(payload) {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    const [error] = await api.requestRestorePassword(payload);

    let errorElem = null;
    let successElem = null;

    if (error) {
      errorElem = <p>{error.message}</p>;
    } else {
      successElem = <p>Indicaciones para restablecer contraseña han sido enviados al correo electronico.</p>;
    }

    dispatch(toggleLoading(false));

    return [errorElem, successElem];
  };
}

export function restorePassword(payload) {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    const [error] = await api.restorePassword(payload);

    let errorElem = null;
    let successElem = null;

    if (error) {
      if (typeof error.message == "object") {
        errorElem = (
          <ul>
            {Object.values(error.message).map((messages) => {
              return messages.map((message) => {
                return <li>{message}</li>;
              });
            })}
          </ul>
        );
      } else {
        errorElem = <p>{error.message}</p>;
      }
    } else {
      successElem = <p>Contraseña restablecida con éxito.</p>;
    }

    dispatch(toggleLoading(false));

    return [errorElem, successElem];
  };
}

