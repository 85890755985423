import React from 'react';

export default function useListInfiniteScroll(listContainer, loadMoreCallback) {
  const [isLoading, setIsLoading] = React.useState(false);
  const isLoadingData = React.useRef(false);
  const nextPage = React.useRef(undefined);
  const loadMore = React.useCallback(
    async (isInitial) => {
      isLoadingData.current = true;
      setIsLoading(true);
      const page = await loadMoreCallback(isInitial);
      isLoadingData.current = false;
      setIsLoading(false);
      nextPage.current = page;
    },
    [loadMoreCallback, setIsLoading]
  );

  React.useEffect(() => {
    if (!loadMoreCallback) return;

    const listContainerNode = listContainer.current;
    if (listContainerNode === null) return;

    loadMore(true);

    const listScroll = listContainerNode.parentNode;
    let listScrollBottom = null;

    async function scroll() {
      listScrollBottom =
        listScrollBottom || listScroll.getBoundingClientRect().bottom + 60;
      if (isLoadingData.current || nextPage.current === null) return;

      const listContainerBottom =
        listContainerNode.getBoundingClientRect().bottom;

      if (listContainerBottom < listScrollBottom) {
        await loadMore();

        if (nextPage.current === null) {
          listScroll.removeEventListener('scroll', scroll);
        }
      }
    }

    listScroll.addEventListener('scroll', scroll);

    return () => {
      if (listContainerNode === null) return;
      listScroll.removeEventListener('scroll', scroll);
    };
  }, [listContainer, loadMore, loadMoreCallback]);

  return isLoading;
}
