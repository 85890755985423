import React from "react";

import Wrapper from "../../components/shared/Wrapper";
import Top from "../../components/Top/Top";

import HowWorksStyled, { Bullet } from "./style";

export default function HowWorks() {
  return (
    <HowWorksStyled>
      <Top backgroundImage="orange-bg.png" />
      <Wrapper>
        <h3>
          ACUMULAR PUNTOS Y GANAR
          <br />
          ¡ES BIEN FÁCIL!
        </h3>
        <ul>
          <li>
            <Bullet color1="#2ba148" color2="#1d8bb1">
              <span>1</span>
            </Bullet>
            <p>
              ESCANEE DESDE SU TELÉFONO EL CÓDIGO QR DE LAS ETIQUETAS DE
              PRODUCTOS SELECCIONADOS UPL.
            </p>
          </li>
          <li>
            <Bullet color1="#f9a41d" color2="#f47b21">
              <span>2</span>
            </Bullet>
            <p>
              ACUMULE INSTANTÁNEAMENTE LOS PUNTOS RESPECTIVOS A CADA PRODUCTO.
            </p>
          </li>
          <li>
            <Bullet color1="#67b2e2" color2="#3856a5">
              <span>3</span>
            </Bullet>
            <p>
              CAMBIE SUS PUNTOS ACUMULADOS POR ATRACTIVOS PREMIOS FÁCIL Y
              RÁPIDO.
            </p>
          </li>
          <li>
            <Bullet color1="#b3d67a" color2="#45c0bc">
              <span>4</span>
            </Bullet>
            <p>
              SIGA ACUMULANDO Y DISFRUTE DE LOS BENEFICIOS EXCLUSIVOS DE FARMERS
              CLUB.
            </p>
          </li>
        </ul>
      </Wrapper>
    </HowWorksStyled>
  );
}
