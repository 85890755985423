import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    main {
        flex-grow: 1;
        overflow: hidden;
    }
`;
