import styled from "styled-components";

export default styled.div`
  --loading-show-speed: 0.4s;
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition-property: opacity, visibility;
  transition-duration: var(--loading-show-speed), 0s;
  transition-delay: 0s, var(--loading-show-speed);

  .alert-modal__container {
    width: 70%;
    background: white;
    border-radius: 1rem;
    box-shadow: 0 0 4rem lightgray;
    overflow: hidden;
    font-size: 1.2rem;
    border: 1px solid lightgray;
  }

  .alert-modal__message {
    padding: 1rem;
  }

  .alert-modal__action {
    display: flex;
    justify-content: center;
    padding: 0 1rem 1rem;
  }

  .alert-modal__ok {
    background-color: transparent;
    font-size: 1.4rem;
    padding: 0.6rem 1rem;
    border: none;
    border-bottom: 2px solid #007230;
  }

  .alert-modal__cancel {
    margin-left: 1rem;
    background-color: transparent;
    font-size: 1.4rem;
    padding: 0.6rem 1rem;
    border: none;
    border-bottom: 2px solid gray;
  }

  h4 {
    font-size: 1.8rem;
    line-height: 2.2rem;
    background-color: #007230;
    color: white;
    padding: 1rem;
    margin: 0 !important;
  }

  li {
    list-style-type: disc;
    margin-left: 2rem;

    + li {
      margin-top: 1rem;
    }
  }

  &.alert-modal--error {
    h4 {
      background-color: #720000;
    }
  }

  &.alert-modal--warning {
    h4 {
      background-color: #f57e1f;
    }
  }

  &.alert-modal--show {
    visibility: visible;
    opacity: 1;
    transition-property: visibility, opacity;
    transition-duration: 0s, var(--loading-show-speed);
    transition-delay: 0s, 0s;
  }
`;
