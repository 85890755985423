import { MY_POINTS_APPEND, MY_POINTS_CLEAN_LIST } from "./actions";

const initialState = {
  nextPage: undefined,
  list: [],
};

export default function myPointsReducer(state = initialState, action) {
  switch (action.type) {
    case MY_POINTS_APPEND: {
      const { list } = state;
      const { list: newList, nextPage } = action.payload;

      return {
        nextPage,
        list: [...list, ...newList],
      };
    }
    case MY_POINTS_CLEAN_LIST:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
