const HOST_URL = process.env.REACT_APP_API_URL;
const genericMessageError = "Ocurrio un error, intente mas tarde.";

class ResponseError extends Error {
  constructor(status, message) {
    super();
    this.status = status;
    this.message = message;
  }
}

const apiHandler = {
  get host() {
    return HOST_URL;
  },
  get authHeader() {
    return { Authorization: `Bearer ${localStorage.getItem("TKU")}` };
  },
  toFormData(obj) {
    const data = new URLSearchParams();
    Object.entries(obj).forEach(([key, value]) => {
      data.append(key, value);
    });

    return data;
  },
  get(endpoint, withAuth) {
    return this.processEndpoint(endpoint, "GET", undefined, withAuth);
  },
  post(endpoint, payload, withAuth) {
    return this.processEndpoint(endpoint, "POST", payload, withAuth);
  },
  put(endpoint, payload, withAuth) {
    return this.processEndpoint(endpoint, "PUT", payload, withAuth);
  },
  delete(endpoint, withAuth) {
    return this.processEndpoint(endpoint, "DELETE", undefined, withAuth);
  },
  processEndpoint(endpoint, method, payload, withAuth = true) {
    const url = `${this.host}${endpoint}`;

    return fetch(url, {
      method,
      headers: {
        ...(withAuth && this.authHeader),
        Accept: "application/json",
      },
      ...(payload && { body: this.toFormData(payload) }),
    })
      .then((response) => {
        switch (response.status) {
          case 200:
          case 201:
            return response.json();
          case 401:
            throw new ResponseError(response.status, "Acceso denegado");
          case 422:
            return response.json().then((data) => {
              throw new ResponseError(response.status, data);
            });
          case 500:
            throw new ResponseError(response.status, genericMessageError);
          default:
            return response.json().then((data) => {
              if (data.message)
                throw new ResponseError(response.status, data.message);
              throw new ResponseError(response.status, genericMessageError);
            });
        }
      })
      .then((data) => [null, data])
      .catch((error) => [error, null]);
  },
};

export default apiHandler;
