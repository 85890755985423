import React from "react";
import style from "styled-components";

const WrapperStyled = style.div`
    padding: 0 2rem 1rem;
    flex-grow: 1;
    overflow-y: auto;

    > div {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        justify-content: ${({ verticalCenter }) =>
          verticalCenter ? "center" : "unset"};
        height: ${({ height }) => height}
    }
`;

export default React.forwardRef(
  ({ className = "", children, verticalCenter, height = "auto" }, ref) => {
    return (
      <WrapperStyled
        className={`main-wrapper ${className}`}
        verticalCenter={verticalCenter}
        height={height}
        ref={ref}
      >
        <div>{children}</div>
      </WrapperStyled>
    );
  }
);
