import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getMyPointsList } from '../../services/points';
import HistroyList from '../../components/HistoryList/HistoryList';
import Top from '../../components/Top/Top';
import Wrapper from '../../components/shared/Wrapper';

import MyPointsHistoryStyled from './style';

export default function MyPointsHistory() {
  const myPointsList = useSelector((state) => state.myPoints.list);
  const dispatch = useDispatch();

  const loadMore = React.useCallback(
    async (isInitial) => {
      /* eslint-disable-next-line no-unused-vars */
      const [_, page] = await dispatch(getMyPointsList(isInitial));

      return page;
    },
    [dispatch]
  );

  return (
    <MyPointsHistoryStyled>
      <Top backgroundImage="orange-bg.png" />
      <Wrapper height="100%">
        <HistroyList
          title="Historial de puntos"
          list={myPointsList}
          loadMore={loadMore}
        />
      </Wrapper>
    </MyPointsHistoryStyled>
  );
}
