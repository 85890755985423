import React from "react";

import Wrapper from "../../components/shared/Wrapper";
import Top from "../../components/Top/Top";

import WhyFarmersStyled from "./style";

export default function WhyFarmers() {
  return (
    <WhyFarmersStyled>
      <Top backgroundImage="green-bg.png" />
      <Wrapper>
        <h3>¿POR QUÉ UN CLUB DE AGRICULTORES?</h3>
        <p>
          EL MUNDO NECESITA LA AGRICULTURA. AGRICULTORES, PRODUCTORES,
          DISTRIBUIDORES Y CONSUMIDORES DEBEMOS ESTAR CONECTADOS, AHORA MÁS QUE
          NUNCA.
        </p>
        <p>
          HEMOS CREADO UN SISTEMA DE ALIMENTACIÓN, HEMOS HECHO NUEVAS ALIANZAS Y
          CONVENIOS DESCUBRIMOS NUEVOS MERCADOS, NUEVAS IDEAS, SOLUCIONES
          SOSTENIBLES, NADA ES IMPOSIBLE.
        </p>
        <p>
          ES UNA RED DE AGRICULTURA ABIERTA, NUTRIENDO EL CRECIMIENTO SOSTENIBLE
          PARA TODOS. NO HAY LÍMITES NI FRONTERAS.
        </p>
        <p>
          <strong>BIENVENIDO A LA NUEVA AGRICULTURA,</strong>
          <br />
          <strong>BIENVENID@ A FARMERS CLUB DE UPL.</strong>
        </p>
      </Wrapper>
    </WhyFarmersStyled>
  );
}
