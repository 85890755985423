const isMobile = () => /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
	navigator.userAgent
);
const isTablet =  () => /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent);

function mainContainerControll() {
	var width = window.innerWidth;
	var height = window.innerHeight;
    const mainContainer = document.getElementById("root");

    mainContainer.style.width = '100%';
	// check mobile
	if (isMobile() && !isTablet()) mainContainer.style.width = `${window.innerWidth}px`;
	// check tablet
	else if (isTablet()) mainContainer.style.width = `${(height * 375) / 541}px`;
	// desktop but width is less than 768 and ratio is more than 4:3 (like 4:3.1)
	else if (width <= height && (width / 3) * 4 >= height) mainContainer.style.width = `${(height * 375) / 541}px`;
	// desktop but width is less than 768
	else if (width <= height) mainContainer.style.width = `${window.innerWidth}px`;
	// desktop default
	else mainContainer.style.width = `${(height * 375) / 541}px`;
}


function calculateFontSize() {
	var width = window.innerWidth;
	var height = window.innerHeight;
	var font_size;
    var htmlElem = document.querySelector('html');
	// screen is mobile

	if (isMobile() && !isTablet()) {
		font_size = (width * 62.5) / 320;
		return htmlElem.style.fontSize =  font_size + '%';
	}
	// screen is more than 768
	if (width >= height) width = (height * 135) / 194;
	// screen is less than 768 but ratio is more than 4:3 (like 4:3.1)
	else if (width <= height && (width / 3) * 4 >= height) width = (height * 135) / 194;
	font_size = (width * 62.5) / 320;

	return htmlElem.style.fontSize =  font_size + '%';
}

function formatPoints(value) {
    const valueStr = parseInt(value).toString().split("");

    for(let i = valueStr.length - 4; i >= 0; i = i - 3) {
        valueStr[i] += "," ;
    }

    return valueStr.join("");
}

export {
    isMobile,
    isTablet,
    calculateFontSize,
    mainContainerControll,
    formatPoints
}