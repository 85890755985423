const INVENTORY_APPEND_PRODUCTS = "INVENTORY_APPEND_PRODUCTS";
const INVENTORY_CLEAN = "INVENTORY_CLEAN";

const appendProducts = (products, nextPage) => {
    return {
        type: INVENTORY_APPEND_PRODUCTS,
        payload: {
            products,
            nextPage
        }
    }
};

const cleanInventory = () => {
    return {
        type: INVENTORY_CLEAN
    }
};

export {
    INVENTORY_APPEND_PRODUCTS,
    INVENTORY_CLEAN,
    appendProducts,
    cleanInventory
}