import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import { toggleMenu } from '../../store/ui/actions'

import TopBarStyled from './style';

export default function TopBar() {
    const dispatch = useDispatch()
    const showTopBar = useSelector(state => state.ui.showTopBar)
    const user = useSelector(state => state.user);
    const cart = useSelector(state => state.shoppingCart);
    const history = useHistory();

    const goBack = () => {
        history.goBack();
    }

    const menuClick = () => {
        dispatch(toggleMenu(true))
    }

    if (!showTopBar) return null

    return <TopBarStyled>
        <div className="top-bar-left">
            <span onClick={goBack}>
                <FontAwesome name="long-arrow-left"/>
            </span>
            <img src={`${process.env.PUBLIC_URL}/images/logo-white.svg`} alt="logo app"/>
        </div>
        <div className="top-bar-right">
            {
                user.id && 
                <Link to="/carrito">
                    <div className="cart-icon">
                        <FontAwesome name="shopping-cart"/>
                        {
                            cart.length ?  
                            <span className="cart-icon__badge">{cart.length}</span>
                            : null
                        }
                    </div>
                </Link>
            }
            <FontAwesome name="bars" onClick={menuClick}/>
        </div>
    </TopBarStyled>;
}