import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  h3 {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 2rem 0 0;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 1rem;
  }

  .points-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #f0f1f1;

    > div span {
      display: block;
      text-transform: uppercase;

      &:first-child {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 0.5rem;

        &::after {
          content: " pts";
          font-size: 1.4rem;
        }
      }
    }

    .points-available span:first-child {
      color: #f57f20;
    }

    .points-used {
      text-align: right;

      span:first-child {
        color: #a3abbc;
      }
    }
  }

  .point-list-wrapper {
    overflow-y: auto;
    flex-grow: 1;
  }

  .point-list {
    li {
      display: flex;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #f0f1f1;
      margin-bottom: 1rem;

      .point-image {
        flex-basis: 6.5rem;
        margin-right: 1rem;
      }

      img {
        width: 100%;
      }

      .point-center {
        flex-grow: 1;
        margin-right: 1rem;
        min-width: 0;
      }

      .point-message,
      .point-value {
        font-weight: 700;
        text-transform: uppercase;
      }

      .point-value {
        white-space: nowrap;
      }
    }
  }

  .more-arrow {
    text-align: center;
    font-size: 3rem;
  }

  .is-loading {
    text-align: center;

    img {
      width: 3rem;
    }
  }
`;
