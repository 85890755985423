export default function Points({points, highlight}) {
    if (points && points !== "0") {
        return <>
            <p>YA TIENES</p>

            { highlight
                ? <>
                    <p className="points-count">{points}</p>
                    <p>PUNTOS</p>
                </>
                : <p>{points} PTS</p>
            }
        </>;
    } else {
        return <>
            <p>NO TIENES</p>
            <p>PUNTOS</p>
        </>;
    }
}