import React, { useRef } from 'react';
import FontAwesome from 'react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Wrapper from '../../components/shared/Wrapper';
import Top from '../../components/Top/Top';
import BlueButton from '../../components/shared/BlueButton';
import ProductItem from '../../components/ProductItem/ProductItem';
import Points from '../../components/Points/Points';
import AlertModal from '../../components/Alert/Alert';

import { getInventories } from '../../services/inventory';
import {
  addItemToCart,
  removeItemFromCart,
} from '../../store/shoppingCart/actions';

import useListInfiniteScroll from '../../hooks/useListInfiniteScroll';

import AwardsStyled from './style';

export default function Awards() {
  const dispatch = useDispatch();
  const listContainer = useRef(null);
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const products = useSelector((state) => state.inventories.products);
  const cart = useSelector((state) => state.shoppingCart);
  const [alertModal, setAlertModal] = React.useState({
    show: false,
    message: null,
    title: null,
    className: null,
  });

  const loadMore = React.useCallback(
    async (isInitial) => {
      /* eslint-disable no-unused-vars */
      console.log(user);
      const [_, page] = await dispatch(getInventories(isInitial, user.is_special, user.id));

      return page;
    },
    [dispatch]
  );

  const showLoading = useListInfiniteScroll(listContainer, loadMore);

  const productsMemoized = React.useMemo(() => {
    return products.map((p) => ({
      ...p,
      checked: cart.some((c) => c.id === p.id),
    }));
  }, [products, cart]);

  const handleGoToCart = () => {
    history.push('/carrito');
  };

  const cartPoints = React.useMemo(() => {
    return cart.reduce((accu, current) => {
      return accu + current.value;
    }, 0);
  }, [cart]);

  const onCheck = (product) => {
    return (check) => {
      if (!check) {
        const count = cartPoints + Number(product.value);
        if (count > user.points) {
          setAlertModal({
            show: true,
            message: 'No tienes suficientes puntos.',
            title: 'Fondos insuficientes',
            className: 'alert-modal--warning',
          });

          return false;
        } else {
          dispatch(addItemToCart(product));
        }
      } else {
        dispatch(removeItemFromCart(product.id));
      }

      return !check;
    };
  };

  const onOkAlertClick = () => setAlertModal({ ...alertModal, show: false });

  return (
    <AwardsStyled>
      <Top backgroundImage="blue-bg.png">
        <div className="points-info">
          <Points points={user.pointsFormat} />
        </div>
      </Top>
      <Wrapper height="100%">
        <h3>PREMIOS DEL PROGRAMA</h3>
        <div className="awards-list">
          <div className="awards-container" ref={listContainer}>
            {productsMemoized.map((p) => (
              <ProductItem
                {...p}
                key={`product-${p.id}`}
                onChecked={onCheck(p)}
              />
            ))}
          </div>
          {showLoading && (
            <div className="awards-loading">
              <img
                src={`${process.env.PUBLIC_URL}/images/loading.gif`}
                alt="icono cargando"
              />
            </div>
          )}
        </div>
        {products.length ? (
          <>
            <div className="more-arrow">
              <FontAwesome name="angle-down" />
            </div>
            <BlueButton onClick={handleGoToCart}>IR AL CARRITO</BlueButton>
          </>
        ) : null}
      </Wrapper>
      <AlertModal
        show={alertModal.show}
        onOkClick={onOkAlertClick}
        message={alertModal.message}
        title={alertModal.title}
        className={alertModal.className}
      />
    </AwardsStyled>
  );
}
