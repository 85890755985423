import api from '../api/farmersApi';
import { appendProducts } from '../store/inventories/actions';
import { formatPoints } from '../utils';
import { logout } from './user';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export function getInventories(isInitial, isSpecial, userId) {
  return async (dispatch, getState) => {
    const {
      inventories: { nextPage, products },
    } = getState();

    if (nextPage === null || (nextPage === undefined && products.length))
      return [null, null];

    if (isInitial && nextPage != null) {
      return [null, nextPage];
    }

    const [error, response] = await api.getInventories((nextPage || 1), isSpecial, userId);

    if (error) {
      if (error.status === 401) dispatch(logout());
    } else {
      const { data, next_page_url } = response;

      const products = data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          value: +item.prices[0].price,
          valueFormat: formatPoints(item.prices[0].price),
          priceId: item.prices[0].id,
          image: `${SERVER_URL}img/inventories/${item.image}`,
        };
      });

      const page = next_page_url && next_page_url.match(/page=(\d+)/);
      const nextPageResponse = (Array.isArray(page) && page[1]) || null;

      dispatch(appendProducts(products, nextPageResponse));

      return [null, nextPageResponse];
    }
    return [error, null];
  };
}
