import React from "react";
import FontAwesome from "react-fontawesome";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { toggleMenu } from "../../store/ui/actions";
import { logout } from "../../services/user";
const MenuStyle = styled.div`
  --tdm-drawer-width: 20rem;
  --tdm-drawer-speed: 0.2s;

  font-size: 1.1rem;

  .menu-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 20;
    visibility: hidden;
    opacity: 0;
    transition-property: opacity, visibility;
    transition-duration: var(--tdm-drawer-speed), 0s;
    transition-delay: 0s, var(--tdm-drawer-speed);
  }

  &.menu--show .menu-backdrop {
    visibility: visible;
    opacity: 1;
    transition-property: visibility, opacity;
    transition-duration: 0s, var(--tdm-drawer-speed);
    transition-delay: 0s, 0s;
  }

  strong {
    font-weight: 700;
  }

  .container {
    z-index: 99999;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: calc(var(--tdm-drawer-width) * -1 - 1rem);
    width: var(--tdm-drawer-width);
    transition: margin-left var(--tdm-drawer-speed);
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    height: 100vh;
    background: white;
    display: inline-block;
    box-shadow: 0 0 1rem 0.2rem;

    .top {
      height: 10.2rem;
      background: #007230;
      padding: 2rem 2.5rem 0;

      > span {
        font-size: 2rem;
        color: white;
      }
    }

    .top-wrapper {
      margin: 1rem 0;
      display: flex;
      align-items: center;
      .profile-pic {
        display: inline-block;
        width: 4rem;
        height: 4rem;
        background-size: cover;
        background-position: center;
        background-color: white;
        border: 0.2rem solid white;
        border-radius: 100%;
        vertical-align: middle;
        margin-right: 1rem;
      }
      .user-info {
        color: white;
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;

        p {
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    nav {
      flex-grow: 1;
      overflow-y: auto;

      padding: 1rem 2.5rem;

      a,
      div {
        padding: 1.2rem 0;
        color: inherit;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .fa {
          font-size: 1.4rem;
        }
      }

      ul + ul {
        border-top: 0.1rem solid #abb6ad;
        margin-top: 4rem;
        padding-top: 1rem;
      }
    }
  }

  &.menu--show .container {
    margin-left: 0;
    transition: margin-left var(--tdm-drawer-speed);
  }
`;

export default function Menu() {
  const showMenu = useSelector((state) => state.ui.showMenu);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const mainNav = React.useRef(null);

  const handleClose = React.useCallback(() => {
    dispatch(toggleMenu(false));
  }, [dispatch]);

  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  const onLogoutClick = () => {
    dispatch(logout());
  };

  React.useEffect(() => {
    const mainNavElem = mainNav.current;

    if (mainNavElem === null) return;

    mainNavElem.addEventListener("click", handleClose);
    return () => {
      if (mainNavElem === null) return;
      mainNavElem.removeEventListener("click", handleClose);
    };
  }, [mainNav, handleClose]);

  return (
    <MenuStyle onClick={handleClose} className={showMenu ? "menu--show" : ""}>
      <div className="menu-backdrop"></div>
      <div className="container" onClick={handleContainerClick}>
        <div className="top">
          <FontAwesome name="long-arrow-left" onClick={handleClose} />
          <div className="top-wrapper">
            {user.id && (
              <>
                <div>
                  <div
                    className="profile-pic"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/user-icon.png)`,
                    }}
                  />
                </div>
                <div className="user-info">
                  <p>
                    <strong>{user.full_name}</strong>
                  </p>
                  <p>Nº {user.id}</p>
                </div>
              </>
            )}
          </div>
        </div>
        <nav id="main-nav" ref={mainNav}>
          <ul>
            {user.id && (
              <>
                <li>
                  <Link
                    replace
                    to={{
                      pathname: "/perfil",
                      search: "?edit=true",
                    }}
                  >
                    <span>EDITAR PERFIL</span>
                    <FontAwesome name="angle-right" />
                  </Link>
                </li>
                <li>
                  <Link to="/puntos">
                    <span>MIS PUNTOS</span>
                    <FontAwesome name="angle-right" />
                  </Link>
                </li>
                <li>
                  <Link to="/premios">
                    <span>CATALOGO DE PREMIOS</span>
                    <FontAwesome name="angle-right" />
                  </Link>
                </li>
                <li>
                  <Link to="/historial-canjes">
                    <span>HISTORIAL DE CANJES</span>
                    <FontAwesome name="angle-right" />
                  </Link>
                </li>
              </>
            )}

            <li>
              <Link to="/por-que-farmers">
                <span>¿POR QUÉ FARMERS CLUB?</span>
                <FontAwesome name="angle-right" />
              </Link>
            </li>
            <li>
              <Link to="/como-funciona">
                <span>¿COMO GANAR PUNTOS?</span>
                <FontAwesome name="angle-right" />
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/">
                <span>CONTÁCTENOS</span>
                <FontAwesome name="angle-right" />
              </Link>
            </li>
            {user.id && (
              <li>
                <div onClick={onLogoutClick}>
                  <span>CERRAR SESIÓN</span>
                  <FontAwesome name="sign-out" />
                </div>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </MenuStyle>
  );
}
