import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form';

import TextInput from '../../components/shared/TextInput';
import GreenBlueButton from '../../components/shared/GreenBlueButton';
import SelectInput from '../shared/SelectInput';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const messages = {
    required: "Campo requerido"
};

// yup.object().shape(

const schemaShape = {
    first_name: yup.string().required(messages.required),
    last_name: yup.string().required(messages.required),
    username: yup.string().required(messages.required),
    email: yup.string().email("Correo invalido").required(messages.required),
    phone: yup.string().required(messages.required)
        .min(7, "Telefono debe ser mayor a 7 digitos")
        .max(14, "Telefono debe ser menor a 14 digitos"),
    gender: yup.string().required(messages.required),
    company_name: yup.string().required(messages.required),
    partner_number: yup.string().required(messages.required),
    password: yup.string().min(8, "Debe tener almenos 8 caracteres").required(messages.required),
    password_confirmation: yup.string().min(8, "Debe tener almenos 8 caracteres").required(messages.required)
        .oneOf([yup.ref('password'), null], 'Contraseña debe coincidir')
};

const FormStyled = styled.form`
    .txt-input {
        margin: 0.5rem 0;

        input, select {
            padding: 0.2rem 0;
        }
    }
`

const DEFAULT_VALUES = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    phone: "",
    gender: 0,
    company_name: "",
    partner_number: "",
    password: "",
    password_confirmation: "",
    birthday: "",
};

const genderOptions = [
    {
        value: 0,
        label: "Masculino"
    },
    {
        value: 1,
        label: "Femenino"
    }
]

export default function ProfileForm ({ onSubmit, defaultValues, hide = {} }) {
    const schemObj = React.useMemo(() => {
        const hasHide = !!Object.keys(hide).length;
        const newShape = hasHide
            ? Object.entries(schemaShape)
                .filter(([key]) => typeof hide[key] === 'undefined')
                .reduce((acc, [key, value]) => {
                    return { ...acc, [key]: value };
                }, {})
            : schemaShape;        
        return yup.object().shape(newShape);
    }, [hide]);

    const { handleSubmit, trigger, formState: { errors, touchedFields },  control } = useForm({
        mode: 'all',
        resolver: yupResolver(schemObj),
        defaultValues: { ...DEFAULT_VALUES, ...defaultValues }
    });

    const onPasswordChange = () => {
        if (!touchedFields.password_confirmation && !defaultValues?.password_confirmation) return;
        trigger("password_confirmation");
    };

    const innerOnSubmit = data => onSubmit && onSubmit(data);

    return <FormStyled onSubmit={handleSubmit(innerOnSubmit)}>
                <TextInput 
                    control={control}
                    errorMessage={errors.first_name?.message}
                    name="first_name"
                    className="txt-input"
                    label="Nombre"
                />
                <TextInput 
                    name="last_name"
                    control={control}
                    errorMessage={errors.last_name?.message}
                    className="txt-input"
                    label="Apellido"
                />
                <SelectInput 
                    name="gender"
                    control={control}
                    errorMessage={errors.gender?.message}
                    className="txt-input"
                    label="Genero"
                    options={genderOptions}
                />
                <TextInput 
                    name="username"
                    control={control}
                    errorMessage={errors.username?.message}
                    className="txt-input"
                    label="Nombre de usuario"
                />
                <TextInput 
                    name="birthday"
                    control={control}
                    errorMessage={errors.birthday?.message}
                    className="txt-input"
                    label="Fecha de nacimiento"
                    type="date"
                />
                <TextInput 
                    name="email"
                    control={control}
                    errorMessage={errors.email?.message}
                    className="txt-input"
                    type="email"
                    label="Correo electronico"
                />
                <TextInput 
                    name="phone"
                    control={control}
                    errorMessage={errors.phone?.message}
                    className="txt-input"
                    label="Telefono"
                />
                <TextInput 
                    name="company_name"
                    control={control}
                    errorMessage={errors.company_name?.message}
                    className="txt-input"
                    label="Empresa"
                    type="text"
                />
                {
                    !hide.password && 
                    <TextInput 
                        name="password"
                        control={control}
                        errorMessage={errors.password?.message}
                        className="txt-input"
                        type="password"
                        label="Contraseña"
                        onKeyUp={onPasswordChange}
                    />
                }
                {
                    !hide.password_confirmation &&
                    <TextInput 
                        name="password_confirmation"
                        control={control}
                        errorMessage={errors.password_confirmation?.message}
                        className="txt-input"
                        type="password"
                        label="Confirmar"
                    />
                }
                {
                    !hide.partner_number &&
                    <TextInput 
                        name="partner_number"
                        control={control}
                        errorMessage={errors.partner_number?.message}
                        className="txt-input"
                        label="Codigo de distribuidor"
                        type="text"
                    />
                }
                <GreenBlueButton>Enviar</GreenBlueButton>
            </FormStyled>
}