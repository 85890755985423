import React from 'react';
import FontAwesome from 'react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Wrapper from '../../components/shared/Wrapper';
import Top from '../../components/Top/Top';
import ProfileForm from '../../components/ProfileForm/ProfileForm';
import AlertModal from '../../components/Alert/Alert';
import { updateUser } from '../../services/user';

import MyPointsStyled from './style';

const hideInputs = {
  password: true,
  password_confirmation: true,
  partner_number: true,
};

const InfoUser = ({ user }) => {
  return (
    <>
      <p className="user-name user-data">
        <strong>
          {user.full_name} | Nº{user.id}
        </strong>
      </p>

      <div className="join-horizontal user-data">
        <p>SOCIO DESDE:</p>
        <p>
          <strong>{user.created_at_format}</strong>
        </p>
      </div>

      <div className="join-horizontal user-data">
        <p>FECHA DE NACIMIENTO:</p>
        <p>
          <strong>{user.birthday_format}</strong>
        </p>
      </div>

      <div className="user-data">
        <p>CORREO ELECTRÓNICO:</p>
        <p>
          <strong>{user.email}</strong>
        </p>
      </div>

      <div className="user-data">
        <p>TELÉFONO:</p>
        <p>
          <strong>{user.phone}</strong>
        </p>
      </div>

      <div className="user-data">
        <p>EMPRESA:</p>
        <p>
          <strong>{user.company_name}</strong>
        </p>
      </div>

      <div className="user-data">
        <p>CONTRASEÑA:</p>
        <p>
          <strong>*************</strong>
        </p>
      </div>
    </>
  );
};

export default function Register({ location }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isEditing, setEditing] = React.useState(false);
  const [alertModal, setAlertModal] = React.useState({
    show: false,
    message: null,
    title: null,
    className: null,
  });

  React.useEffect(() => {
    setEditing(location.search === '?edit=true');
  }, [location.search]);

  const toggleEditing = () => {
    history.replace({
      pathname: '/perfil',
      search: `?edit=${isEditing ? 'false' : 'true'}`,
    });
  };

  const onOkAlertClick = () => setAlertModal({ ...alertModal, show: false });

  const onSubmit = async (data) => {
    const [error, success] = await dispatch(updateUser(data));

    if (error) {
      setAlertModal({
        show: true,
        message: error,
        title: 'Error',
        className: 'alert-modal--error',
      });
    } else {
      setAlertModal({
        show: true,
        message: success,
        title: 'Usuario actualizado',
        className: null,
      });

      toggleEditing();
    }
  };

  return (
    <MyPointsStyled>
      <Top backgroundImage="purple-bg.png">
        <div
          className="profile-pic"
          onClick={toggleEditing}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/user-icon.png)`,
          }}
        >
          <div className="edit-btn">
            <FontAwesome name="pencil" />
          </div>
        </div>
      </Top>
      <Wrapper>
        <h3>{isEditing ? 'EDITAR PERFIL' : 'PERFIL'}</h3>
        {isEditing ? (
          <ProfileForm
            defaultValues={user}
            hide={hideInputs}
            onSubmit={onSubmit}
          />
        ) : (
          <InfoUser user={user} />
        )}
      </Wrapper>
      <AlertModal
        show={alertModal.show}
        onOkClick={onOkAlertClick}
        message={alertModal.message}
        title={alertModal.title}
        className={alertModal.className}
      />
    </MyPointsStyled>
  );
}
