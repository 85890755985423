import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Wrapper from '../../components/shared/Wrapper'
import Top from '../../components/Top/Top'
import BlueButton from '../../components/shared/BlueButton'
import ProductItem from '../../components/ProductItem/ProductItem'
import Points from '../../components/Points/Points';
import AlertModal from '../../components/Alert/Alert'

import { removeItemFromCart } from '../../store/shoppingCart/actions';
import { processOrder } from '../../services/orders';
import { formatPoints } from '../../utils'; 

import ShoppingCartStyle from './style';

export default function Awards() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const cart = useSelector(state => state.shoppingCart);
    const [confirmModal, setConfirmModal] = React.useState({
        productId: null,
        show: false,
        message: null,
        title: null
    });

    const [alertModal, setAlertModal] = React.useState({
        show: false,
        message: null,
        title: null,
        className: null
    });

    const pointsToConsume = React.useMemo(() => {
        return cart.reduce((acc, cur) => {
            return acc + cur.value
        }, 0)
    }, [cart]);

    const pointsToConsumeFormat = React.useMemo(() => {
        return formatPoints(pointsToConsume);
    }, [pointsToConsume]);

    const remainingPointsFormat = React.useMemo(() => {
        return formatPoints(user.points - pointsToConsume)
    }, [pointsToConsume, user.points]);

    const closeConfirmModal = () => {
        setConfirmModal({ ...confirmModal, show: false });
    }

    const closeAlertModal = () => {
        setAlertModal({ ...alertModal, show: false });
    }

    const onOkConfirmModal = () => {
        dispatch(removeItemFromCart(confirmModal.productId))
        closeConfirmModal();
    }

    const onCheck = (product) => {
        return () => {
            setConfirmModal({
                show: true, 
                title: "Confirmación", 
                message: `¿Esta seguro que desea eliminar ${product.name} del carrito?`,
                productId: product.id
            });

            return true;
        }
    }

    const onProcess = async () => {
        const error = await dispatch(processOrder());

        if (error) {
            setAlertModal({
                show: true,
                title: "Error",
                message: error.message,
                className: "alert-modal--error"
            });
        } else {
            setAlertModal({
                show: true,
                title: "Orden procesada",
                message: "La orden se proceso exitosamente",
                className: null
            });
        }
    };

    return <ShoppingCartStyle>
        <Top backgroundImage="blue-bg.png">
            <div className="points-info">
                <Points points={user.pointsFormat} />
            </div>
        </Top>
        <Wrapper>
            <h3>PREMIOS DEL PROGRAMA</h3>
            <div className="awards-list">
                { cart.map(p => <ProductItem {...p} key={`product-${p.id}`} checked onChecked={onCheck(p)} />) }
            </div>
            {
                cart.length ? 
                <>
                    <div className="cart-points">
                        <p>SALDO ACTUAL: {user.pointsFormat} PTS</p>
                        <p>REDENCIÓN: {pointsToConsumeFormat} PTS</p>
                    </div>
                    <p><strong>SALDO FINAL: {remainingPointsFormat} PTS</strong></p>
                    <BlueButton onClick={onProcess}>¡QUIERO MI PREMIO!</BlueButton>
                </>
                : <div className="cart-message">
                    <p>Carrito esta vacio</p>
                </div>
            }
        </Wrapper>

        <AlertModal 
            show={confirmModal.show}
            okLabel="Si"
            onOkClick={onOkConfirmModal}
            onCancelClick={closeConfirmModal}
            message={confirmModal.message}
            title={confirmModal.title}
            className="alert-modal--warning"
        />

        <AlertModal 
            show={alertModal.show}
            onOkClick={closeAlertModal}
            message={alertModal.message}
            title={alertModal.title}
            className={alertModal.className}
        />
    </ShoppingCartStyle>;
}
