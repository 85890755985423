import {
    UI_TOGGLE_TOPBAR,
    UI_TOGGLE_BOTTOM_NAV,
    UI_TOGGLE_MENU,
    UI_TOGGLE_LOADING } from "./actions";

const initialState = {
    showTopBar: true,
    showBottomNav: true,
    showMenu: false,
    showLoading: false,
}

export default function uiReducer(state = initialState, action) {
    switch (action.type) {
        case UI_TOGGLE_TOPBAR:
            return { ...state, showTopBar: action.payload }
        case UI_TOGGLE_BOTTOM_NAV:
            return { ...state, showBottomNav: action.payload }
        case UI_TOGGLE_MENU:
            return { ...state, showMenu: action.payload }
        case UI_TOGGLE_LOADING:
            return { ...state, showLoading: action.payload }
        default:
            return state
    }
}