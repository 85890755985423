import { INVENTORY_APPEND_PRODUCTS, INVENTORY_CLEAN } from "./actions";

const initialState = {
  products: [],
  nextPage: undefined,
};

export default function inventoriesReducer(state = initialState, action) {
  switch (action.type) {
    case INVENTORY_APPEND_PRODUCTS: {
      const { products, ...rest } = state;
      const { products: newProducts, nextPage: newNextPage } = action.payload;
      return {
        ...rest,
        products: [...products, ...newProducts],
        nextPage: newNextPage,
      };
    }
    case INVENTORY_CLEAN:
      return { ...initialState };
    default:
      return state;
  }
}
