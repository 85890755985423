import React from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Wrapper from "../../components/shared/Wrapper";
import Top from "../../components/Top/Top";
import TextInput from "../../components/shared/TextInput";
import GreenBlueButton from "../../components/shared/GreenBlueButton";
import CheckboxControlled from "../../components/shared/CheckboxControlled";

import { toggleTopBar, toggleBottomNav } from "../../store/ui/actions";
import { login } from "../../services/user";

import LoginStyled from "./style";
import AlertModal from "../../components/Alert/Alert";

const messages = {
  required: "Campo requerido",
};

const schema = yup.object().shape({
  username: yup.string().required(messages.required),
  // email: yup.string().email("Correo invalido").required(messages.required),
  password: yup.string().required(messages.required),
});

const DEFAULT_VALUES = {
  username: "",
  password: "",
  rememberMe: true,
};

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [alertModal, setAlertModal] = React.useState({
    show: false,
    message: null,
  });

  React.useEffect(() => {
    dispatch(toggleTopBar(false));
    dispatch(toggleBottomNav(false));

    return () => {
      dispatch(toggleTopBar(true));
      dispatch(toggleBottomNav(true));
    };
  }, [dispatch]);

  const onSubmit = async (data) => {
    const [error] = await dispatch(login(data));

    if (error) {
      setAlertModal({
        show: true,
        message: error,
      });
    } else {
      history.go("/");
    }
  };

  const onOkAlertClick = () => setAlertModal({ ...alertModal, show: false });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  return (
    <LoginStyled>
      <Top height="18" backgroundImage="login-bg.png">
        <img
          className="logo"
          src={`${process.env.PUBLIC_URL}/images/green-logo.png`}
          alt="logo app"
        />
      </Top>
      <Wrapper>
        <h2>¡Hola!</h2>
        <p className="info">
          ACTIVE LOS CÓDIGOS QR EN PRODUCTOS SELECCIONADOS, GANE Y DISFRUTE DE
          LOS BENEFICIOS DE ESTE CLUB ESPECIAL.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            control={control}
            name="username"
            className="txt-input"
            type="text"
            placeholder="Username"
            iconName="user"
            errorMessage={errors.username?.message}
          />
          <TextInput
            name="password"
            control={control}
            className="txt-input"
            type="password"
            placeholder="Password"
            iconName="lock"
            errorMessage={errors.password?.message}
          />
          <div className="remember-me">
            <CheckboxControlled
              id="rememberMe"
              name="rememberMe"
              control={control}
            />
            <label htmlFor="rememberMe">Recordarme</label>
          </div>
          <GreenBlueButton type="submit">Entrar</GreenBlueButton>
        </form>
        <p className="retore-password"><Link to="/solicitar-restablecer-contrasena">Restablecer contraseña</Link></p>
        <p className="register">
          ¿NUEVO? REGÍSTRESE <Link to="/registro">AQUÍ</Link>
        </p>
      </Wrapper>
      <AlertModal
        show={alertModal.show}
        onOkClick={onOkAlertClick}
        message={alertModal.message}
        title="Error"
        className="alert-modal--error"
      />
    </LoginStyled>
  );
}
