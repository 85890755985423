import React from "react";
import AlertModalStyled from "./style";

export default function AlertModal({
  title,
  message,
  show,
  className = "",
  onOkClick,
  onCancelClick,
  okLabel = "Ok",
  cancelLabel = "Cancelar",
}) {
  const classes = [className, show ? "alert-modal--show" : ""];

  return (
    <AlertModalStyled className={classes.join(" ")}>
      <div className="alert-modal__container">
        <h4>{title}</h4>
        <div className="alert-modal__message">{message}</div>
        <div className="alert-modal__action">
          {onOkClick && (
            <button className="alert-modal__ok" onClick={onOkClick}>
              {okLabel}
            </button>
          )}
          {onCancelClick && (
            <button className="alert-modal__cancel" onClick={onCancelClick}>
              {cancelLabel}
            </button>
          )}
        </div>
      </div>
    </AlertModalStyled>
  );
}
