import { combineReducers } from "redux";

import uiReducer from "./ui/reducer";
import userReducer from "./user/reducer";
import inventoriesReducer from "./inventories/reducer";
import shoppingCartReducer from "./shoppingCart/reducer";
import ordersDetailsReducer from "./ordersDetails/reducers";
import myPointsReducer from "./myPoints/reducers";

export default combineReducers({
  ui: uiReducer,
  user: userReducer,
  inventories: inventoriesReducer,
  shoppingCart: shoppingCartReducer,
  ordersDetails: ordersDetailsReducer,
  myPoints: myPointsReducer,
});
