import styled from "styled-components";
import Page from "../../mixins/Page";

export default styled.div`
  ${Page}

  h3 {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 2rem 0 0;
    font-weight: 700;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid #abb6ad;
  }

  p {
    font-size: 1.1rem;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.5rem;

    &.info {
      margin-bottom: 2rem;
    }
  }

  .txt-input {
    margin: 0.5rem 0;

    input,
    select {
      padding: 0.2rem 0;
    }
  }

  .legal {
  }

  button {
    margin-bottom: 1rem;
  }
`;
