import React from "react";
import FontAwesome from "react-fontawesome";
import { useSelector, useDispatch } from "react-redux";

import useListInfiniteScroll from "../../hooks/useListInfiniteScroll";
import { fetchOrders } from "../../services/orders";

import HistoryListStyled from "./style";

export default function HistroyList({ title, list, loadMore }) {
  const dispatch = useDispatch();
  const listContainer = React.useRef(null);
  const isLoading = useListInfiniteScroll(listContainer, loadMore);
  const user = useSelector((state) => state.user);

  React.useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  return (
    <HistoryListStyled>
      <h3>{title}</h3>
      <div className="points-info">
        <div className="points-available">
          <span>{user.pointsFormat}</span>
          <span>Saldo disponible</span>
        </div>
        <div className="points-used">
          <span>{user.pointsUsedFormat}</span>
          <span>Canjeado</span>
        </div>
      </div>

      <div className="point-list-wrapper">
        <ul className="point-list" ref={listContainer}>
          {list.map((point) => {
            return (
              <li key={point.id}>
                {point.image && (
                  <div className="point-image">
                    <img src={point.image} alt="imagen de producto" />
                  </div>
                )}
                <div className="point-center">
                  <p className="point-message">{point.title}</p>
                  <p className="point-registered">{point.registeredFormat}</p>
                </div>
                <p className="point-value">{point.valueFormat}</p>
              </li>
            );
          })}
        </ul>
        {isLoading && list.length && (
          <div className="is-loading">
            <img
              src={`${process.env.PUBLIC_URL}/images/loading.gif`}
              alt="icono cargando"
            />
          </div>
        )}
      </div>
      {list.length ? (
        <>
          <div className="more-arrow">
            <FontAwesome name="angle-down" />
          </div>
        </>
      ) : null}
    </HistoryListStyled>
  );
}
