import styled from "styled-components";
import Page from "../../mixins/Page";

export default styled.div`
  ${Page}

  .top-wrapper {
    .logo {
      width: 16rem;
      display: block;
      margin: 2.5rem auto 0;
    }
  }

  h2 {
    font-size: 3.2rem;
    line-height: 3.6rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }

  p {
    font-size: 1.1rem;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.5rem;

    &.info {
      margin-bottom: 2rem;
    }
  }

  .txt-input {
    margin: 0.7rem 0;
  }

  .register {
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid #abb6ad;
  }

  .retore-password {
    margin: 0 0 1.5rem;
  }

  button {
    margin: 2rem 0;
  }

  .remember-me {
    position: relative;

    label[for] {
      font-size: 1.2rem;
      position: relative;
      padding-left: 2rem;
    }

    .fa {
      font-size: 1.6rem;
      position: absolute;
    }
  }
`;
