import React from 'react';
import style from 'styled-components';

const TopStyled = style.div`
    background-image: url('${process.env.PUBLIC_URL}/images/${ props => props.backgroundImage }');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
    > div {
        padding-top: 1rem;
        height: ${ props => props.height || '11' }rem;
    }
`;

export default React.forwardRef(({ className = '', children, height, backgroundImage }, ref) => {
    return <TopStyled 
            className={`top-wrapper
            ${className}`} 
            ref={ref}
            height={height}
            backgroundImage={backgroundImage}
        >
            <div>
                { children }
            </div>
        </TopStyled>;
});
