import apiHandler from "./apiHandler";

const farmersApi = {
  requestRestorePassword(payload) {
    return apiHandler.post("password/reset-request", payload, false);
  },
  restorePassword(payload) {
    return apiHandler.post("password/reset", payload, false);
  },
  register(payload) {
    return apiHandler.post("register", payload, false);
  },
  login(payload) {
    return apiHandler.post("login", payload, false);
  },
  logout() {
    return apiHandler.post("logout");
  },
  getUserData() {
    return apiHandler.get("me");
  },
  updateUser(id, payload) {
    return apiHandler.put(`users/${id}`, payload);
  },
  getInventories(page, isSpecial, userId) {
    return apiHandler.get(`inventories/client/list/${isSpecial == "1" ? 1 : 0}/${userId}?page=${page}`);
  },
  createOrder(payload) {
    return apiHandler.post("orders", payload);
  },
  updateOrder(id, payload) {
    return apiHandler.put(`orders/${id}`, payload);
  },
  deleteOrder(id) {
    return apiHandler.delete(`orders/${id}`);
  },
  createOrderDetail(payload) {
    return apiHandler.post("order/details", payload);
  },
  countUserPoints(id) {
    return apiHandler.get(`user/points/${id}/count`);
  },
  getPointByKey(key) {
    return apiHandler.get(`points/${key}`);
  },
  addPointToUser(payload) {
    return apiHandler.post("user/points", payload);
  },
  getOrdersByUserId(id) {
    return apiHandler.get(`user/orders/${id}`);
  },
  getUserPointsList(id, page) {
    return apiHandler.get(`points/list/${id}?page=${page}`);
  },
};

export default farmersApi;
