import React from "react";
import FontAwesome from "react-fontawesome";
import { useSelector } from "react-redux";

import Wrapper from "../../components/shared/Wrapper";
import Top from "../../components/Top/Top";
import Points from "../../components/Points/Points";

import MyPointsStyled from "./style";
import { Link } from "react-router-dom";

export default function MyPoints() {
  const user = useSelector((state) => state.user);

  return (
    <MyPointsStyled>
      <Top backgroundImage="orange-bg.png">
        <div
          className="profile-pic"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/user-icon.png)`,
          }}
        />
      </Top>
      <Wrapper>
        <h3>Mis puntos</h3>
        <p>
          <strong>SOCIO: {user.full_name}</strong>
        </p>
        <p>
          Nº DE SOCIO: {user.id}, DESDE {user.created_at_format}
        </p>
        <p>EMPRESA: {user.company_name}</p>
        <div className="points-wrapper">
          <Points points={user.pointsFormat} highlight />
        </div>
        <Link to="/historial-puntos" className="btn">
          <span>HISTORIAL DE PUNTOS</span>
          <FontAwesome name="angle-right" />
        </Link>
      </Wrapper>
    </MyPointsStyled>
  );
}
