import styled from "styled-components";

export default styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  display: flex;
  flex-direction: column;
  background: rgb(31, 158, 72);
  background: linear-gradient(
    145deg,
    rgba(31, 158, 72, 1) 0%,
    rgba(109, 197, 112, 1) 100%
  );
  z-index: 40;
  animation: fade 0.5s linear 1s forwards;

  @keyframes fade {
    0% {
      opacity: 1;
    }
    100% {
      opacity: ${(prop) => (prop.forceShow ? 1 : 0)};
    }
  }

  .splash {
    &__logo {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 16rem;
      }
    }

    &__footer {
      text-align: center;
      margin: 4rem;
      img {
        width: 8rem;
      }
    }
  }
`;
