import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux'

const BottomNavigationStyled = styled.div`
    --loading-show-speed: .4s;
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(255,255,255,0.6);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition-property: opacity, visibility;
    transition-duration: var(--loading-show-speed), 0s;
    transition-delay: 0s, var(--loading-show-speed);

    img {
        width: 4rem;
    }

    &.loading--show {
        visibility: visible;
        opacity: 1;
        transition-property: visibility, opacity;
        transition-duration: 0s, var(--loading-show-speed);
        transition-delay: 0s, 0s;
    }
`;

export default function Loading() {
    const showLoading = useSelector(state => state.ui.showLoading )

    return <BottomNavigationStyled className={showLoading ? "loading--show": ""}>
            <img src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="icono de cargando"/>
        </BottomNavigationStyled>;
}