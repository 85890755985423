import moment from 'moment';

import api from '../api/farmersApi';
import { formatPoints } from '../utils';
import { toggleLoading } from '../store/ui/actions';
import { logout } from './user';
import { appendMyPointsList } from '../store/myPoints/actions';

export function fetchPoint(key) {
  return async (dispatch) => {
    dispatch(toggleLoading(true));

    const [error, data] = await api.getPointByKey(key);

    if (error) {
      if (error.status === 401) dispatch(logout());
    }

    dispatch(toggleLoading(false));

    return [error, data];
  };
}

export function getMyPointsList(isInitial) {
  return async (dispatch, getState) => {
    const {
      user: { id: userId },
      myPoints: { nextPage, list },
    } = getState();

    if (nextPage === null || (nextPage === undefined && list.length))
      return [null, null];

    if (isInitial && nextPage != null) {
      return [null, nextPage];
    }

    const [error, response] = await api.getUserPointsList(
      userId,
      nextPage || 1
    );

    if (error) {
      if (error.status === 401) dispatch(logout());
    } else {
      const { data, next_page_url } = response;

      const list = data.map((item) => {
        const registered = item.users.find(
          (user) => +user.user_id === userId
        )?.created_at;

        return {
          id: item.id,
          title: item.message,
          value: +item.value,
          valueFormat: `+ ${formatPoints(item.value)}`,
          registered,
          registeredFormat: registered
            ? moment(registered, 'YYYY-MM-DD').format('DD MMM YY')
            : '-',
        };
      });

      const page = next_page_url && next_page_url.match(/page=(\d+)/);
      const nextPageResponse = (Array.isArray(page) && page[1]) || null;

      dispatch(appendMyPointsList(list, nextPageResponse));

      return [null, nextPageResponse];
    }

    return [error, null];
  };
}
