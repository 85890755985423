import React from "react";
import QrReader from "react-qr-reader";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import Wrapper from "../../components/shared/Wrapper";
import Top from "../../components/Top/Top";
import GreenBlueButton from "../../components/shared/GreenBlueButton";
import BaseGradientButton from "../../components/shared/BaseGradientButton";
import AlertModal from "../../components/Alert/Alert";
import { addPoint } from "../../services/user";
import { fetchPoint } from "../../services/points";
import { formatPoints } from "../../utils";

import ScanStyled from "./style";

const initialPointDetail = {
  id: null,
  key: null,
  message: null,
  value: null,
};

const ScanResult = ({ pointDetail, onAddClick, onCancelClick }) => {
  return (
    <>
      <Top backgroundImage="green-bg.png" />
      <Wrapper verticalCenter>
        <div
          className="qr-result"
          style={{ visibility: pointDetail.key ? "visible" : "hidden" }}
        >
          <h2>¡MUY BIEN! UD AGREGARÁ:</h2>
          <div className="product-info">
            <p>{pointDetail.message}</p>
          </div>
          <h3>
            {pointDetail.value}
            <span>PTS</span>
          </h3>
          <GreenBlueButton onClick={onAddClick}>AGREGAR PUNTOS</GreenBlueButton>
          <BaseGradientButton
            className="cancel-btn"
            color1="lightgray"
            color2="gray"
            onClick={onCancelClick}
          >
            CANCELAR
          </BaseGradientButton>
        </div>
      </Wrapper>
    </>
  );
};

export default function Awards() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isScanning, setIsScanning] = React.useState(true);
  const [pointDetail, setPointDetail] = React.useState(initialPointDetail);
  const [options, setOptions] = React.useState(null);
  const [alertModal, setAlertModal] = React.useState({
    show: false,
    message: null,
    title: null,
    className: null,
  });

  const handleError = console.error;
  const handleScan = async (data) => {
    if (!data) return;
    setIsScanning(false);
    navigator?.vibrate && navigator.vibrate(200);

    const [error, point] = await dispatch(fetchPoint(data));

    if (error) {
      setIsScanning(true);

      setAlertModal({
        show: true,
        title: "Error",
        message: <p>{error.message}</p>,
        className: "alert-modal--error",
      });
    } else {
      setPointDetail({
        id: point.id,
        message: point.message,
        value: formatPoints(point.value),
        key: point.key,
      });
    }
  };

  const onAddClick = async () => {
    const error = await dispatch(addPoint(pointDetail.key));

    if (error) {
      setAlertModal({
        show: true,
        title: "Error",
        message: <p>{error.message}</p>,
        className: "alert-modal--error",
      });
    } else {
      setAlertModal({
        show: true,
        title: "Puntos agregados",
        message: <p>Puntos agregados con éxito.</p>,
        className: null,
      });
    }
  };

  const onOkAlertClick = () => {
    setAlertModal({ ...alertModal, show: false });

    if (alertModal.title !== "Error") {
      history.push("/");
    }
  };

  const onCancelClick = () => {
    setIsScanning(true);
    setPointDetail(initialPointDetail);
  };

  const setCam = React.useCallback(
    (extra = {}) => {
      let options = {
        facingMode: "environment",
        key: "cam-qr-reader",
        ...extra,
      };
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        try {
          navigator.mediaDevices.enumerateDevices().then(function (devices) {
            devices.forEach(function (device) {
              if (device.kind === "videoinput") {
                if (device.label.toLowerCase().search("back") > -1) {
                  options.deviceId = { exact: device.deviceId };
                  options.key = "cam-qr-reader-done";
                }
              }
            });
            setOptions(options);
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        setOptions(options);
      }
    },
    [setOptions]
  );

  const onCamReady = React.useCallback(() => {
    if (options.key === "cam-qr-reader") {
      setTimeout(() => setCam({ key: "cam-qr-reader-done" }), 300);
    }
  }, [setCam, options]);

  React.useEffect(() => setCam(), [setCam]);

  return (
    <ScanStyled>
      {isScanning ? (
        options && (
          <QrReader
            key={options.key}
            delay={300}
            onError={handleError}
            onScan={handleScan}
            resolution={720}
            constraints={options}
            onLoad={onCamReady}
            style={{ width: "100%", height: "100vh" }}
          />
        )
      ) : (
        <ScanResult
          pointDetail={pointDetail}
          onAddClick={onAddClick}
          onCancelClick={onCancelClick}
        />
      )}

      <AlertModal
        show={alertModal.show}
        onOkClick={onOkAlertClick}
        message={alertModal.message}
        title={alertModal.title}
        className={alertModal.className}
      />
    </ScanStyled>
  );
}
