import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'

const BottomNavigationStyled = styled.nav`
    background-color: #f8f8f8;
    text-align: center;
    padding: 0.8rem 1rem 1rem;
    display: flex;
    justify-content: space-between;


    a {
        display: inline-block;
        width: 7rem;
        font-size: 1rem;
        color: #6a7d6e;
        text-decoration: none;
        padding: 0.4rem 0;
        border-radius: 0.8rem;

        img {
            width: 100%;
            padding: 0 0.6rem
        }

        &.current {
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            
            > * {
                visibility: hidden;
            }

            &--orange {
                background-image: url("${process.env.PUBLIC_URL}/images/puntos-BTN.png");
            }

            &--green {
                background-image: url("${process.env.PUBLIC_URL}/images/QR-BTN.png");
            }

            &--blue {
                background-image: url("${process.env.PUBLIC_URL}/images/premios-btn.png");
            }

            &--purple {
                background-image: url("${process.env.PUBLIC_URL}/images/mi-perfil-btn.png");
            }
        }
    }
`;

export default function BottomNavigation() {
    const location = useLocation();

    const showBottomNav = useSelector(state => state.ui.showBottomNav )

    if (!showBottomNav) return null

    return <BottomNavigationStyled>
        <Link to="/escanear" className={ location.pathname === "/escanear" ? "current current--green" : "" }>
            <img src={`${process.env.PUBLIC_URL}/images/escaner-btn.png`} alt="icono de escaner"/>
            <span>Escanear</span>
        </Link>
        <Link to="/puntos" className={ ["/", "/puntos"].includes(location.pathname) ? "current current--orange" : "" }>
            <img src={`${process.env.PUBLIC_URL}/images/mis-puntos-btn.png`} alt="icono de puntos"/>
            <span>Mis Puntos</span>
        </Link>
        <Link to="/premios" className={ location.pathname === "/premios" ? "current current--blue" : "" }>
            <img src={`${process.env.PUBLIC_URL}/images/mis-premios-btn.png`} alt="icono de premios"/>
            <span>Mis Premios</span>
        </Link>
        <Link to="/perfil" className={ location.pathname === "/perfil" ? "current current--purple" : "" }>
            <img src={`${process.env.PUBLIC_URL}/images/PERFIL-btn.png`} alt="icono de perfil"/>
            <span>Mi Perfil</span>
        </Link>
    </BottomNavigationStyled>;
}