const CART_ADD_ITEM = "CART_ADD_ITEM";
const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
const CART_CLEAN = "CART_CLEAN";

const addItemToCart = (product) => {
    return {
        type: CART_ADD_ITEM,
        payload: product
    }
};

const removeItemFromCart = (id) => {
    return {
        type: CART_REMOVE_ITEM,
        payload: id
    }
};

const cleanCart = () => {
    return {
        type: CART_CLEAN
    }
};

export {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_CLEAN,
    addItemToCart,
    removeItemFromCart,
    cleanCart
}