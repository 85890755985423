import React from "react";
import { Route, Redirect } from "react-router-dom";

import useComponentWithUser from "../../hooks/useComponentWithUser";

const ToLogin = () => <Redirect to="/login" />;

const SwitchRoute = ({ withUser, withOutUser = ToLogin, ...rest }) => {
  const render = useComponentWithUser(withUser, withOutUser);

  return <Route {...rest} render={render} />;
};

export default SwitchRoute;
