import styled from 'styled-components'

export default styled.div`
    display: flex;
    margin: 1rem 0;

    .product {
        &-info {
            flex-grow: 1;
            flex-basis: 0;
        }

        &-image {
            flex-basis: 9rem;
            padding-right: 2rem;
            img {
                max-width: 100%;
                max-height: 9rem;
            }
        }

        &-check {
            width: 2rem;
            font-size: 2rem;
        }

        &-value {
            color: #f57e1f;
        }
    }
`;