import { css } from "styled-components";

export default css`
  height: 100%;
  display: flex;
  flex-direction: column;

  strong {
    font-weight: 700;
  }
`;
