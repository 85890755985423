import React from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";

import MyPoints from "../MyPoints/MyPoints";
import Login from "../Login/Login";
import Register from "../Register/Register";
import Profile from "../Profile/Profile";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import Awards from "../Awards/Awards";
import Scan from "../Scan/Scan";
import TopBar from "../../components/TopBar/TopBar";
import HowWorks from "../HowWorks/HowWorks";
import WhyFarmers from "../WhyFarmers/WhyFarmers";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import Menu from "../../components/Menu/Menu";
import Splash from "../../components/Splash/Splash";
import SwitchRoute from "../../components/SwitchRoute/SwitchRoute";
import AlertModal from "../../components/Alert/Alert";
import RequestRestorePassword from "../RequestRestorePassword/RequestRestorePassword";
import RestorePassword from "../RestorePassword/RestorePassword";

import { calculateFontSize, mainContainerControll } from "../../utils";

import AppStyled from "./style";
import Loading from "../../components/Loading/Loading";

import useAutoLogin from "../../hooks/useAutoLogin";
import AwardsHistory from "../AwardsHistory/AwardsHistory";
import MyPointsHistory from "../MyPointsHistory/MyPointsHistory";

const ToPoints = () => <Redirect to="/" />;

export default function App() {
  const { userLoaded, userLoadedStatus, errorMessage } = useAutoLogin();

  React.useEffect(() => {
    let timeoutId = null;

    const updateLayout = () => {
      mainContainerControll();
      calculateFontSize();
    };

    const resize = () => {
      window.clearTimeout(timeoutId);
      timeoutId = setTimeout(updateLayout, 300);
    };

    updateLayout();

    window.addEventListener("resize", resize, true);

    return () => {
      window.removeEventListener("resize", resize, true);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Loading />
        <Splash forceShow={!!errorMessage} />
        <TopBar />
        {userLoaded !== userLoadedStatus.pending && (
          <AppStyled>
            <main>
              <SwitchRoute path={["/", "/puntos"]} exact withUser={MyPoints} />
              <SwitchRoute
                path="/login"
                withUser={ToPoints}
                withOutUser={Login}
              />
              <SwitchRoute
                path="/registro"
                withUser={ToPoints}
                withOutUser={Register}
              />
              <SwitchRoute path="/perfil" withUser={Profile} />
              <SwitchRoute path="/premios" withUser={Awards} />
              <SwitchRoute path="/carrito" withUser={ShoppingCart} />
              <SwitchRoute path="/escanear" withUser={Scan} />
              <SwitchRoute path="/historial-canjes" withUser={AwardsHistory} />
              <SwitchRoute
                path="/historial-puntos"
                withUser={MyPointsHistory}
              />
              <SwitchRoute path="/solicitar-restablecer-contrasena" withUser={Profile} withOutUser={RequestRestorePassword} />
              <SwitchRoute path="/restablecer-password" withUser={Profile} withOutUser={RestorePassword} />
              <Route path="/como-funciona" render={HowWorks} />
              <Route path="/por-que-farmers" render={WhyFarmers} />
            </main>
            <BottomNavigation />
          </AppStyled>
        )}
        <Menu />
        <AlertModal
          show={!!errorMessage}
          onOkClick={() => {}}
          message={errorMessage}
          title="Error"
          className="alert-modal--error"
        />
      </BrowserRouter>
    </>
  );
}
