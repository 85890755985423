import React from 'react';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';

const CheckboxStyled = styled.label`
    position: relative;
    .fa-check-square-o {
        display: none;
        position: absolute;
        left: 0;
    }

    input {
        display: none;
        &:checked {
            & ~ .fa-square-o {
                visibility: hidden;
            }

            & ~ .fa-check-square-o {
                display: inline;
            }
        }
    }
`;

export default React.forwardRef(({ field = {}, ...rest }, ref) => {
    return <CheckboxStyled ref={ref}>
        <input { ...field }  { ...rest } type="checkbox"/>
        <FontAwesome name="square-o" />
        <FontAwesome name="check-square-o" />
    </CheckboxStyled>;
});
