import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Wrapper from "../../components/shared/Wrapper";
import Top from "../../components/Top/Top";
import RestorePasswordForm from "../../components/RestorePasswordForm/RestorePasswordForm";

import { toggleBottomNav } from "../../store/ui/actions";
import { restorePassword } from "../../services/user";

import RegisterStyled from "./style";
import AlertModal from "../../components/Alert/Alert";

export default function RestorePassword() {
  const dispatch = useDispatch();
  const history = useHistory();

  const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    const email = url.searchParams.get('email');

    useEffect(() => {
        if (!token || !email) {
          history.push('/');
        }
    }, []);

  const [alertModal, setAlertModal] = React.useState({
    show: false,
    message: null,
    isSuccess: null,
    title: null,
    className: null,
  });

  React.useEffect(() => {
    dispatch(toggleBottomNav(false));

    return () => {
      dispatch(toggleBottomNav(true));
    };
  }, [dispatch]);

  const onSubmit = async (data) => {
    const [error, success] = await dispatch(restorePassword({...data, email, token}));

    setAlertModal({
      show: true,
      message: error || success,
      isSuccess: !!success,
      title: !!success ? "Cambio exitoso" : "Error",
      className: !success ? "alert-modal--error" : "",
    });
  };

  const onOkAlertClick = () => {
    setAlertModal({ ...alertModal, show: false });

    if (alertModal.isSuccess) history.push('/login');
  };

  return (
    <RegisterStyled>
      <Top backgroundImage="form-registro-bg.png" />
      <Wrapper>
        <h3>RESTABLECER CONTRASEÑA</h3>
        <br></br>
        <RestorePasswordForm onSubmit={onSubmit} />
      </Wrapper>
      <AlertModal
        show={alertModal.show}
        onOkClick={onOkAlertClick}
        message={alertModal.message}
        title={alertModal.title}
        className={alertModal.className}
      />
    </RegisterStyled>
  );
}
