const USER_LOAD = "USER_LOAD";
const USER_RESET = "USER_RESET";
const USER_UPDATE_POINTS = "USER_UPDATE_POINTS";
const USER_UPDATE_POINTS_USED = "USER_UPDATE_POINTS_USED";

const resetUser = () => {
  localStorage.removeItem("TKU");
  return {
    type: USER_RESET,
  };
};

const loadUser = (user) => {
  return {
    type: USER_LOAD,
    payload: user,
  };
};

const updateUserPoints = (points) => {
  return {
    type: USER_UPDATE_POINTS,
    payload: points,
  };
};

const updateUserPointsUsed = (points) => {
  return {
    type: USER_UPDATE_POINTS_USED,
    payload: points,
  };
};

export {
  USER_LOAD,
  USER_RESET,
  USER_UPDATE_POINTS,
  USER_UPDATE_POINTS_USED,
  resetUser,
  loadUser,
  updateUserPoints,
  updateUserPointsUsed,
};
