import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { getUserData } from "../services/user";

const userLoadedStatus = {
  pending: 0,
  allowed: 1,
  rejected: 2,
};

export default function useAutoLogin() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.id);
  const [userLoaded, setUserLoaded] = React.useState(userLoadedStatus.pending);
  const loading = React.useRef(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => {
    const getUser = async () => {
      if (loading.current) return;
      loading.current = true;

      const error = await dispatch(getUserData());

      if (error) {
        if (error.status === 403) setUserLoaded(userLoadedStatus.rejected);
        else setErrorMessage(error.message);
      } else setUserLoaded(userLoadedStatus.allowed);
    };

    if (!!localStorage.getItem("TKU")) {
      if (userId) setUserLoaded(userLoadedStatus.allowed);
      else getUser();
    } else {
      setUserLoaded(userLoadedStatus.rejected);
    }
  }, [loading, dispatch, userId]);

  return { userLoaded, userLoadedStatus, errorMessage };
}
