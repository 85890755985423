import styled from "styled-components";

import Page from "../../mixins/Page";

export default styled.div`
  ${Page}

  h3 {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 2rem 0 0;
    font-weight: 700;
    padding-bottom: 1rem;
  }

  p {
    padding: 1rem 0;
    line-height: 1.6rem;
  }
`;
