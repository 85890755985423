import styled from 'styled-components';

export default styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    font-size: 1.8rem;
    color: white;
    padding: 2rem 2rem 0;
    justify-content: space-between;
    z-index: 10;

    .top-bar-left, .top-bar-right {
        margin: -0.7rem -0.7rem 0;
        > * {
            padding: 0.7rem;
            vertical-align: top;
            display: inline-block;
        }
    }

    a {
        color: white;
    }

    img {
        width: 8.5rem;
    }

    .cart-icon{
        position: relative;

        &__badge {
            position: absolute;
            border-radius: 100%;
            background-color: #e87930;
            font-size: 1rem;
            width: 1.2rem;
            height: 1.2rem;
            text-align: center;
            line-height: 1.3rem;
            top: -0.4rem;
            left: 1.1rem;
        }
    }
`;
