const UI_TOGGLE_TOPBAR = "UI_TOGGLE_TOPBAR";
const UI_TOGGLE_BOTTOM_NAV = "UI_TOGGLE_BOTTOM_NAV";
const UI_TOGGLE_MENU = "UI_TOGGLE_MENU";
const UI_TOGGLE_LOADING = "UI_TOGGLE_LOADING";


const toggleTopBar = (show) => {
    return {
        type: UI_TOGGLE_TOPBAR,
        payload: show, 
    }
};

const toggleBottomNav = (show) => {
    return {
        type: UI_TOGGLE_BOTTOM_NAV,
        payload: show
    }
};

const toggleMenu = (show) => {
    return {
        type: UI_TOGGLE_MENU,
        payload: show
    }
};

const toggleLoading = (show) => {
    return {
        type: UI_TOGGLE_LOADING,
        payload: show
    }
};

export {
    UI_TOGGLE_TOPBAR,
    UI_TOGGLE_BOTTOM_NAV,
    UI_TOGGLE_MENU,
    UI_TOGGLE_LOADING,
    toggleTopBar,
    toggleBottomNav,
    toggleMenu,
    toggleLoading
}