import React from "react";
import { useSelector } from "react-redux";

import HistroyList from "../../components/HistoryList/HistoryList";
import Top from "../../components/Top/Top";
import Wrapper from "../../components/shared/Wrapper";

import AwardsHistoryStyled from "./style";

export default function AwardsHistory() {
  const ordersDetails = useSelector((state) => state.ordersDetails);

  return (
    <AwardsHistoryStyled>
      <Top backgroundImage="blue-bg.png" />
      <Wrapper height="100%">
        <HistroyList title="Historial de canjes" list={ordersDetails} />
      </Wrapper>
    </AwardsHistoryStyled>
  );
}
