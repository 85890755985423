import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form';

import TextInput from '../../components/shared/TextInput';
import GreenBlueButton from '../../components/shared/GreenBlueButton';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const messages = {
    required: "Campo requerido"
};

const schemaShape = {
    password: yup.string().min(8, "Debe tener almenos 8 caracteres").required(messages.required),
    password_confirmation: yup.string().min(8, "Debe tener almenos 8 caracteres").required(messages.required)
        .oneOf([yup.ref('password'), null], 'Contraseña debe coincidir')
};

const schemaForm = yup.object().shape(schemaShape);

const FormStyled = styled.form`
    .txt-input {
        margin: 0.5rem 0;
    }

    button {
        margin-top: 2rem;
    }
`

const DEFAULT_VALUES = {
    password: "",
    password_confirmation: "",
};

export default function RestorePasswordForm ({ onSubmit, defaultValues, hide = {} }) {
    const { handleSubmit, trigger, formState: { errors, touchedFields },  control } = useForm({
        mode: 'all',
        resolver: yupResolver(schemaForm),
        defaultValues: { ...DEFAULT_VALUES, ...defaultValues }
    });

    const onPasswordChange = () => {
        if (!touchedFields.password_confirmation && !defaultValues?.password_confirmation) return;
        trigger("password_confirmation");
    };

    const innerOnSubmit = data => onSubmit && onSubmit(data);

    return <FormStyled onSubmit={handleSubmit(innerOnSubmit)}>
                <TextInput 
                    name="password"
                    control={control}
                    errorMessage={errors.password?.message}
                    className="txt-input"
                    type="password"
                    label="Contraseña"
                    onKeyUp={onPasswordChange}
                />
                <TextInput 
                    name="password_confirmation"
                    control={control}
                    errorMessage={errors.password_confirmation?.message}
                    className="txt-input"
                    type="password"
                    label="Confirmar"
                />
                <GreenBlueButton>Enviar</GreenBlueButton>
            </FormStyled>
}