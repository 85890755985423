import { ORDERS_DETAILS_GET } from "./actions";

export default function ordersDetailsReducer(state = [], action) {
  switch (action.type) {
    case ORDERS_DETAILS_GET:
      return action.payload;
    default:
      return state;
  }
}
